import './CarteLinks.css';

const CarteLinks = () => {
    return (
        <div className='carte-links-container'>
            <div className='carte-link-btn'>
                <a href='/la-carte/les-hors-doeuvre'>- LES HORS D&#39;OEUVRE -</a>
            </div>

            <div className='carte-link-btn'>
                <a href='/la-carte/les-pizzas'>- NOS PIZZAS CUITES AU FEU DE BOIS -</a>
            </div>

            <div className='carte-link-btn'>
                <a href='/la-carte/les-viandes'>- NOS VIANDES GRILLÉES AU FEU DE BOIS -</a>
            </div>

            <div className='carte-link-btn'>
                <a href='/la-carte/les-poissons'>- LES POISSONS -</a>
            </div>

            <div className='carte-link-btn'>
                <a href='/la-carte/les-desserts'>- LES DESSERTS -</a>
            </div>
        </div>
    );
};

export default CarteLinks;
