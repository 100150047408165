import React, { useEffect} from "react";

import LandingSection from 'pages/HomePage/LandingSection/LandingSection';
import AboutSection from 'pages/HomePage/AboutSection/AboutSection';
import CarteResto from 'components/CarteResto/CarteResto';

import './HomePage.css';

const HomePage = () => {
  useEffect(() => {
    document.title = "Restaurant L'HERMITAGE - Sausset les Pins - Page d'acceuil";  
  }, []);

    return (
        <div id='homepage-main'>
            <LandingSection />
            <AboutSection />
            <CarteResto />
        </div>
    );
};

export default HomePage;
