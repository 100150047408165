import { useEffect } from 'react';

import histoirePic1 from 'assets/photos/Histoire-pic1-mobile.png';
import histoirePic2 from 'assets/photos/Histoire-pic2-mobile.png';
import './Histoire.css';

const Histoire = () => {
    useEffect(() => {
        document.title = "Découvrez l'Histoire du Restaurant L'HERMITAGE";
    }, []);

    return (
        <div className='page-histoire-main'>
            <div className='page-histoire-grid'>
                <h1 className='page-histoire-titre'>NOTRE HISTOIRE</h1>

                <img
                    src={histoirePic1}
                    className='histoire-pic1'
                    alt="camping et buvette de l'Hermitage dans les années 80"
                />

                <div className='page-histoire-text1'>
                    <p>En activité depuis 1958, &#34;L&#39;Hermitage&#34; est un établissement emblématique du village de Sausset-les-Pins.</p>

                    <br />

                    <p>Tout a commencé avec une simple buvette pour le camping établi sur la corniche.</p>

                    <br />

                    <p>
                        Au fil du temps, la buvette est devenue un authentique Restaurant/Pizzeria de tradition artisanale et le village de <br /> Sausset-les-Pins s&#39;est étendu jusqu&#39;à
                        remplacer le camping...
                    </p>

                    <br />

                    <p>Mais le restaurant L&#39;Hermitage est resté une institution locale appréciée des habitants et des vacanciers.</p>
                </div>

                <img
                    src={histoirePic2}
                    className='histoire-pic2'
                    alt="camping et buvette de l'Hermitage dans les années 70"
                />

                <div className='page-histoire-text2'>
                    <p>Récemment rénové, notre restaurant dispose d&#39;une terrasse et d&#39;une salle pouvant accueillir chacune une centaine de couverts.</p>

                    <br />

                    <p>Le restaurant a conservé son four à bois traditionnel et propose des spécialités comme les pizzas et les grillades de viandes de boeuf maturées.</p>

                    <br />

                    <p>Nous vous attendons pour déguster nos bons plats en bord de mer !</p>
                </div>

                <a
                    href='/la-carte'
                    className='page-histoire-btn-area'>
                    <button className='page-histoire-btn cta-btn'>Consultez notre Carte !</button>
                </a>
            </div>
        </div>
    );
};

export default Histoire;
