const lesHorsDoeuvre = [
    {
        nom: 'Planche de Charcuterie',
        ingredients: null,
        prix: '18,00',
    },
    {
        nom: 'Terrine de Foie Gras mi-cuit maison',
        ingredients: '',
        prix: '21,00',
    },
    {
        nom: 'Salade Caesar',
        ingredients: "poulet mariné, poulet pané en nuggets, croûtons à l'ail, copeaux de parmesan, sauce Caesar, cressin",
        prix: '18,00',
    },
    {
        nom: 'Salade de Chèvre chaud',
        ingredients: 'chèvre pané, Samossa de chèvre chaud, lardons grillés, pignons grillés, cressin',
        prix: '18,00',
    },
    {
        nom: 'Salade Mozzarella di Bufala',
        ingredients: 'Carpaccio de tomates au pistou, mozzarella di Bufala, tomates cerises et mozzarella',
        prix: '17,50',
    },
    {
        nom: 'Ardoise Jambon Ibérique',
        ingredients: '',
        prix: '21,00',
    },
    {
        nom: 'Saint-Marcellin gratiné et chiffonade de Jambon cru',
        ingredients: '',
        prix: '18,00',
    },
    {
        nom: "Salade à l'ail",
        ingredients: '',
        prix: '6,50',
    },
    {
        nom: 'Salade Verte',
        ingredients: '',
        prix: '5,50',
    },
];

// ---------------------------------------------------

// 2ÈME PARTIE DU MENU:
const lesPoissons = [
    {
        nom: 'Petite friture Joëls',
        ingredients: '',
        prix: '15,00',
    },
    {
        nom: 'Gambas',
        rappel: '(5 pièces)',
        ingredients: '',
        prix: '26,00',
    },
    {
        nom: "Rondelles d'Encornets frits",
        ingredients: '',
        prix: '26,00',
    },
    {
        nom: 'Brochettes de noix de Saint-Jacques panées',
        ingredients: '',
        prix: '24,00',
    },
    {
        nom: 'La Sole Meunière',
        rappel: '(les 100gr)',
        ingredients: '',
        prix: '11,00',
    },
    {
        nom: "Le Loup d'origine sauvage",
        rappel: '(les 100gr)',
        ingredients: 'au feu de bois',
        prix: '9,00',
    },
    {
        nom: "La Daurade d'origine sauvage",
        rappel: '(les 100gr)',
        ingredients: 'au feu de bois',
        prix: '9,00',
    },
];
// ---------------------------------------------------

// 3ÈME PARTIE DU MENU:
const lesViandes = [
    {
        categorie: 'Le Boeuf',
        nom: 'Filet Grillé',
        ingredients: '',
        prix: '32,00',
    },
    {
        categorie: 'Le Boeuf',
        nom: 'Filet Poivre Vert ou Roquefort',
        ingredients: '',
        prix: '35,00',
    },
    {
        categorie: 'Le Boeuf',
        nom: 'Côte de Boeuf maturée',
        rappel: '1 personne',
        ingredients: '',
        prix: '38,00',
    },
    {
        categorie: 'Le Boeuf',
        nom: 'Côte de Boeuf maturée',
        rappel: '2 personnes',
        ingredients: '',
        prix: '76,00',
    },
    {
        categorie: 'Le Boeuf',
        nom: "Bavette de boeuf à l'échalotte",
        rappel: '',
        ingredients: '',
        prix: '23,00',
    },
    {
        categorie: 'Le Boeuf',
        nom: 'Entrecôte grillée maturée',
        rappel: '',
        ingredients: '',
        prix: '29,00',
    },
    {
        categorie: 'Le Boeuf',
        nom: 'Tartare haché minute',
        rappel: '',
        ingredients: '',
        prix: '23,00',
    },
    {
        categorie: 'Le Boeuf',
        nom: 'Wok de boeuf aux nouilles chinoises & légumes croquants',
        rappel: '',
        ingredients: '',
        prix: '21,00',
    },

    {
        categorie: 'Le Porc',
        nom: 'Andouillette Grillée',
        rappel: '',
        ingredients: '',
        prix: '21,00',
    },
    {
        categorie: 'Le Porc',
        nom: 'Saussice Italienne poivrée',
        rappel: '',
        ingredients: '',
        prix: '20,00',
    },
    {
        categorie: "L'Agneau",
        nom: 'Brochette Chiche-Kebab',
        rappel: '',
        ingredients: "(gigot d'agneau, tomates, échalottes)",
        prix: '21,00',
    },
    {
        categorie: "L'Agneau",
        nom: "Côtelletes d'Agneau",
        rappel: '3 pièces',
        ingredients: '',
        prix: '22,00',
    },
    {
        categorie: "L'Agneau",
        nom: "Épaule d'Agneau",
        rappel: '2 personnes',
        ingredients: '',
        prix: '51,00',
    },
    {
        categorie: 'Le Canard',
        nom: 'Magret de Canard grillé',
        rappel: '',
        ingredients: '',
        prix: '23,00',
    },
];

// ---------------------------------------------------

// 4ÈME PARTIE DU MENU:
const lesPizzas = [
    {
        nom: 'Anchois',
        ingredients: '',
        prix: {
            petite: '13,00',
            grande: '14,00',
        },
    },
    {
        nom: 'Fromage',
        ingredients: '(emmental)',
        prix: {
            petite: '13,00',
            grande: '14,00',
        },
    },
    {
        nom: 'Jambon',
        ingredients: '(emmental, jambon)',
        prix: {
            petite: '14,00',
            grande: '16,00',
        },
    },
    {
        nom: 'Napolitaine',
        ingredients: '(mozzarella, anchois)',
        prix: {
            petite: '16,00',
            grande: '18,00',
        },
    },
    {
        nom: 'Mozzarella',
        ingredients: '(fromage)',
        prix: {
            petite: '15,00',
            grande: '16,00',
        },
    },
    {
        nom: 'Corsoise',
        ingredients: "(brousse, figatelli, huile d'olive)",
        prix: {
            petite: '17,00',
            grande: '19,50',
        },
    },
    {
        nom: 'Royale',
        ingredients: '(mozzarella, champignons, jambon)',
        prix: {
            petite: '17,00',
            grande: '19,00',
        },
    },
    {
        nom: 'Hermitage',
        ingredients: '(mozzarella, saucisse italienne)',
        prix: {
            petite: '17,00',
            grande: '19,50',
        },
    },
    {
        nom: 'Quattre Fromages',
        ingredients: '(emmental, mozzarella, roquefort, chèvre)',
        prix: {
            petite: '18,00',
            grande: '19,50',
        },
    },
    {
        nom: 'Sicilienne',
        ingredients: '(mozzarella, aubergines, parmesan)',
        prix: {
            petite: '17,00',
            grande: '19,00',
        },
    },
    {
        nom: 'Supions',
        ingredients: '(petites sèches, sauce tomate)',
        prix: {
            petite: '17,00',
            grande: '19,00',
        },
    },
];
// ---------------------------------------------------

// 5ÈME PARTIE DU MENU:
const lesDesserts = [
    {
        categorie: '',
        nom: 'Plateau de Fromages',
        ingredients: '',
        prix: '9,00',
    },
    {
        categorie: 'GLACES & SORBETS',
        nom: 'GLACES - 3 boules au choix',
        ingredients: 'Pistache, chocolat, fraise, café, vanille, malaga, menthe, caramel beurre salé, marron',
        prix: '6,00',
    },
    {
        categorie: 'GLACES & SORBETS',
        nom: 'SORBETS - 3 boules au choix',
        ingredients: 'Citron, poire, passion, noix de coco, pomme, cassis, framboise, mangue, barbe à papa',
        prix: '6,00',
    },
    {
        categorie: 'GLACES & SORBETS',
        nom: 'NOUGAT GLACÉ et son coulis de fruits rouges',
        ingredients: '',
        prix: '7,50',
    },
    {
        categorie: 'GLACES & SORBETS',
        nom: 'Mystere',
        ingredients: '',
        prix: '6,00',
    },
    {
        categorie: 'GLACES & SORBETS',
        nom: 'Coupe Chantilly',
        ingredients: '',
        prix: '4,00',
    },
    {
        categorie: 'GLACES & SORBETS',
        nom: 'Supplément Chantilly ou Crème de Marron',
        ingredients: '',
        prix: '1,50',
    },

    {
        categorie: 'DESSERTS MAISON',
        nom: 'Flan Caramel',
        prix: '6,50',
    },
    {
        categorie: 'DESSERTS MAISON',
        nom: 'Flan Chantilly',
        prix: '8,00',
    },
    {
        categorie: 'DESSERTS MAISON',
        nom: 'Mousse au chocolat',
        prix: '6,50',
    },
    {
        categorie: 'DESSERTS MAISON',
        nom: 'Profiterolles au chocolat',
        prix: '9,00',
    },
    {
        categorie: 'DESSERTS MAISON',
        nom: 'Tiramisu',
        prix: '7,50',
    },
    {
        categorie: 'DESSERTS MAISON',
        nom: 'Crème brulée',
        prix: '7,50',
    },
    {
        categorie: 'DESSERTS MAISON',
        nom: 'Café Gourmand',
        prix: '7,50',
    },

    {
        categorie: 'FRUITS',
        nom: 'Coupe de Fraises',
        prix: '6,50',
    },
    {
        categorie: 'FRUITS',
        nom: 'Fraises Chantilly',
        prix: '8,00',
    },
    {
        categorie: 'FRUITS',
        nom: 'Fraises Melba',
        prix: '9,00',
    },
    {
        categorie: 'FRUITS',
        nom: 'Melon',
        prix: '6,50',
    },
    {
        categorie: 'GLACES & SORBETS COMPOSÉS',
        nom: 'Pêche Melba',
        ingredients: 'Glace vanille, peche, chantilly, coulis de fruits rouges',
        prix: '7,50',
    },
    {
        categorie: 'GLACES & SORBETS COMPOSÉS',
        nom: 'Dame Café',
        ingredients: 'Glace café, chantilly, chocolat chaud',
        prix: '7,50',
    },
    {
        categorie: 'GLACES & SORBETS COMPOSÉS',
        nom: 'Dame ou Chocolat Liégeois',
        ingredients: 'Glace café, chantilly, chocolat chaud',
        prix: '7,50',
    },
    {
        categorie: 'GLACES & SORBETS COMPOSÉS',
        nom: 'Poire Belle Hélène',
        ingredients: 'Glace vanille, poire, chocolat chaud, chantilly',
        prix: '7,50',
    },
    {
        categorie: 'GLACES & SORBETS COMPOSÉS',
        nom: 'Mystère Grand Marnier',
        ingredients: '',
        prix: '8,50',
    },
    {
        categorie: 'GLACES & SORBETS COMPOSÉS',
        nom: 'Sorbet Williams',
        ingredients: 'Sorbet poire, poire Williams',
        prix: '9,00',
    },
    {
        categorie: 'GLACES & SORBETS COMPOSÉS',
        nom: 'Colonel',
        ingredients: 'Sorbet citron, vodka',
        prix: '9,00',
    },
    {
        categorie: 'GLACES & SORBETS COMPOSÉS',
        nom: 'Coupe blanche',
        ingredients: 'Malaga, chantilly, rhum blanc',
        prix: '9,00',
    },
    {
        categorie: 'GLACES & SORBETS COMPOSÉS',
        nom: 'Marie Stuart',
        ingredients: 'Glace pistache, chaucolat chaud, gin',
        prix: '9,00',
    },
    {
        categorie: 'GLACES & SORBETS COMPOSÉS',
        nom: 'Trou de Provence',
        ingredients: 'Sorbet pomme verte, Marc de Provence',
        prix: '9,00',
    },
    {
        categorie: 'GLACES & SORBETS COMPOSÉS',
        nom: 'Dame Blanche',
        ingredients: 'Glace vanille, chocolat chaud, chantilly',
        prix: '7,50',
    },
    {
        categorie: 'GLACES & SORBETS COMPOSÉS',
        nom: 'After Eight',
        ingredients: 'Glace menthe, chocolat, Get 27',
        prix: '9,00',
    },
    {
        categorie: 'GLACES & SORBETS COMPOSÉS',
        nom: "L'Ardéchoise",
        ingredients: '2 boules vanille, 1 boule marron, crème de marron, chantilly',
        prix: '7,50',
    },
];

export default { lesHorsDoeuvre, lesPoissons, lesPizzas, lesViandes, lesDesserts };
