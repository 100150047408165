import leMenu from 'data/Menu_2022';

import losange from 'assets/icons/mini-losanges.png';

import '../LaCarte.css';
import PricingInfo from 'components/PricingInfo';

const LesHorsDoeuvre = () => {
    const menu = leMenu.lesHorsDoeuvre;

    return (
        <>
            <div className='menu-subsection-container'>
                <h2 className='menu-subsection-title'>LES HORS D&#39;&OElig;UVRE</h2>

                <div className='subsection-meals-list'>
                    {menu.map((plat) => {
                        return (
                            <div
                                key={`container-${plat.nom}`}
                                className='menu-item'>
                                <div className='menu-left'>
                                    <img
                                        src={losange}
                                        className='losange-menu-subsection'
                                        key={`losange-${plat.nom}`}
                                        alt='losange'
                                    />

                                    <div className='menu-middle'>
                                        <h4
                                            className='meal-name'
                                            key={menu.nom}>
                                            {plat.nom}
                                        </h4>

                                        {plat.ingredients && (
                                            <p
                                                className='ingredients'
                                                key={`description-${plat.nom}`}>
                                                ({plat.ingredients})
                                            </p>
                                        )}
                                    </div>
                                </div>

                                <div className='menu-right'>
                                    <h4 className='price'>{plat.prix}</h4>
                                </div>
                            </div>
                        );
                    })}
                </div>

                <a
                    className='retour-vers-carte'
                    href='/la-carte'>
                    <button className='cta-btn'>RETOUR</button>
                </a>
            </div>

            <PricingInfo />
        </>
    );
};

export default LesHorsDoeuvre;
