import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import LandingCarouselPic1 from 'assets/photos/landing-carousel-1.jpg';
import LandingCarouselPic2 from 'assets/photos/landing-carousel-2.jpg';
import LandingCarouselPic3 from 'assets/photos/landing-carousel-3.jpg';
import LandingCarouselPic4 from 'assets/photos/landing-carousel-4.jpg';

import './LandingCarousel.css';

// eslint-disable-next-line react/prop-types
export const LandingCarouselItem = ({ children, width, numero }) => {
    // 'LandingCarousel' will be the main controller to control the items.
    // The 'LandingCarouselItem' component will render the item itself (We just need to pass the width property to this component for now).
    return (
        <div
            id={`landing-carousel-pic-${numero}`}
            className='landing-carousel-item'
            style={{ width: width }}>
            {children}
        </div>
    );
};

export const LandingCarousel = ({ children }) => {
    const [activeIndexLandingCarousel, setActiveIndexLandingCarousel] = useState(0);

    // THIS METHOD IS ALSO USED IN 'MenuSectionCarousel' COMPONENT SO I SHOULD PROBABLY STORE IT AS A SERVICE (and import it here)
    // This method will receive a new index and check if the 'active' item is the first item or the last item:
    // this will the carousel infinite (it starts back after displaying the last picture)
    const updateIndex = (newIndex) => {
        // If the new index less than 0, we just reset it reset it to number of items — 1
        if (newIndex < 0) {
            newIndex = React.Children.count(children) - 1;
        }
        // If the new index equal or greater than number of items , just reset it to 0
        else if (newIndex >= React.Children.count(children)) {
            newIndex = 0;
        }

        // Call setActiveIndexLandingCarousel to update the active state
        setActiveIndexLandingCarousel(newIndex);
    };

    // Auto-cycle for infinite-carousel:
    useEffect(() => {
        const interval = setInterval(() => {
            updateIndex(activeIndexLandingCarousel + 1);
        }, 3000);
        // interval of 3000 miliseconds = 3 seconds. (That means it will execute the updateIndex() method every 2 seconds)

        return () => {
            if (interval) {
                clearInterval(interval);
                // we need to do the clean-up to ensure our interval will stop running when our component is unmount
            }
        };
    });
    LandingCarousel.propTypes = {
        children: PropTypes.node.isRequired,
    };

    return (
        <div className='landing-carousel'>
            <div
                className='inner'
                style={{ transform: `translateX(-${activeIndexLandingCarousel * 100}%)` }}>
                {/* in "translateX(-${activeIndexLandingCarousel * 100}%)" above, we are using the value "100" because we have 100% width for each item. 
                For example, if we want to have 2 active (visible) items (so 50% width for each) and we want to be able to slide item by item, then the number should be 50 (=> 50% each). */}
                {React.Children.map(children, (child, index) => {
                    return React.cloneElement(child, { width: '100%' });
                })}
            </div>
        </div>
    );
};

const LandingCarouselManager = () => {
    return (
        <div id='landing-carousel-main-container'>
            <LandingCarousel>
                <LandingCarouselItem>
                    <img
                        className='landing-carousel-images'
                        id='landing-carousel-img-2'
                        src={LandingCarouselPic2}
                        alt='landing-carousel-2'
                    />
                </LandingCarouselItem>

                <LandingCarouselItem>
                    <img
                        className='landing-carousel-images'
                        id='landing-carousel-img-1'
                        src={LandingCarouselPic1}
                        alt='landing-carousel-1'
                    />
                </LandingCarouselItem>

                <LandingCarouselItem>
                    <img
                        className='landing-carousel-images'
                        id='landing-carousel-img-3'
                        src={LandingCarouselPic3}
                        alt='landing-carousel-3'
                    />
                </LandingCarouselItem>

                <LandingCarouselItem>
                    <img
                        className='landing-carousel-images'
                        id='landing-carousel-img-4'
                        src={LandingCarouselPic4}
                        alt='landing-carousel-4'
                    />
                </LandingCarouselItem>
            </LandingCarousel>
        </div>
    );
};
export default LandingCarouselManager;
