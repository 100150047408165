import React, { useEffect} from "react";

import CarteLinks from 'components/CarteLinks/CarteLinks';
import MenuCarousel, { MenuCarouselItem } from 'components/Carousels/MenuCarousel/MenuCarousel';

import MenuCarouselPic1 from 'assets/photos/horsdoeuvre1.png';
import MenuCarouselPic2 from 'assets/photos/horsdoeuvre2.png';
import MenuCarouselPic3 from 'assets/photos/pizza1.jpg';
import MenuCarouselPic4 from 'assets/photos/pizza2.png';
import MenuCarouselPic5 from 'assets/photos/viande-crue2.jpg';
import MenuCarouselPic11 from 'assets/photos/viande2.png';
import MenuCarouselPic6 from 'assets/photos/viande3.jpg';
import MenuCarouselPic7 from 'assets/photos/poisson1.jpg';
import MenuCarouselPic8 from 'assets/photos/poisson3.png';
import MenuCarouselPic9 from 'assets/photos/dessert1.png';
import MenuCarouselPic10 from 'assets/photos/dessert2.jpg';

import './CarteResto.css';

const CarteResto = () => {
  const currentLocation = window.location.pathname;

  useEffect(() => {
    if (currentLocation.includes('la-carte') ) {

      document.title = "Carte du Restaurant L'HERMITAGE - Sausset les Pins";  
    }
  }, []);
  
    return (
        <section id='carte-resto-main'>
            <h1 id='menu-title'>CARTE DU RESTAURANT</h1>

            <CarteLinks />

            <MenuCarousel>
                <MenuCarouselItem>
                    <img
                        src={MenuCarouselPic1}
                        alt="nom du hors d'oeuvre"
                    />
                </MenuCarouselItem>
                <MenuCarouselItem>
                    <img
                        src={MenuCarouselPic2}
                        alt='nom de la pizza'
                    />
                </MenuCarouselItem>

                <MenuCarouselItem>
                    <img
                        src={MenuCarouselPic3}
                        alt='nom plat viande'
                    />
                </MenuCarouselItem>
                <MenuCarouselItem>
                    <img
                        src={MenuCarouselPic4}
                        alt='nom plat poisson'
                    />
                </MenuCarouselItem>
                <MenuCarouselItem>
                    <img
                        src={MenuCarouselPic5}
                        alt='nom du dessert'
                    />
                </MenuCarouselItem>

                <MenuCarouselItem>
                    <img
                        src={MenuCarouselPic11}
                        alt='nom du dessert'
                    />
                </MenuCarouselItem>

                <MenuCarouselItem>
                    <img
                        src={MenuCarouselPic6}
                        alt='nom plat viande'
                    />
                </MenuCarouselItem>
                <MenuCarouselItem>
                    <img
                        src={MenuCarouselPic7}
                        alt='nom plat poisson'
                    />
                </MenuCarouselItem>
                <MenuCarouselItem>
                    <img
                        src={MenuCarouselPic8}
                        alt='nom du dessert'
                    />
                </MenuCarouselItem>
                <MenuCarouselItem>
                    <img
                        src={MenuCarouselPic9}
                        alt='nom plat poisson'
                    />
                </MenuCarouselItem>
                <MenuCarouselItem>
                    <img
                        src={MenuCarouselPic10}
                        alt='nom du dessert'
                    />
                </MenuCarouselItem>
            </MenuCarousel>

            <div id='galerie-btn-div'>
                <a href='galerie-photos'>
                    <button className='cta-btn'>GALERIE PHOTOS</button>
                </a>
            </div>
        </section>
    );
};

export default CarteResto;
