import { useState, useEffect } from 'react';

import downArrows from 'assets/icons/down-arrows.png';
import scrollToFooter from 'services/scrollToFooter';

import './MiniFooter.css';

const MiniFooter = () => {
    const [isVisible, setIsVisible] = useState(true);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        window.addEventListener('scroll', listenToScroll);
        return () => window.removeEventListener('scroll', listenToScroll);
    }, []);

    const listenToScroll = () => {
        let heightToHideFrom = 40;
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        setHeight(winScroll);

        if (winScroll > heightToHideFrom) {
            isVisible && setIsVisible(false);
        } else {
            setIsVisible(true);
        }
    };

    return (
        <div id='small-footer-main'>
            {isVisible && (
                <div id='hide'>
                    <p className='small-footer-text'>OUVERT 7j/7</p>

                    <div id='minifooter-arrows-div'>
                        <img
                            onClick={scrollToFooter}
                            src={downArrows}
                            alt='flèches vers le bas'
                        />
                    </div>

                    <p className='small-footer-text'>
                        <a
                            className='small-footer-text'
                            href='tel:0033442450982'>
                            04 42 45 09 82
                        </a>
                    </p>
                </div>
            )}
        </div>
    );
};

export default MiniFooter;
