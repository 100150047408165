import { useState, useEffect } from 'react';

import fb_icon from 'assets/icons/icon-facebook.png';
import insta_icon from 'assets/icons/icon-insta.png';
import snap_icon from 'assets/icons/icon-snap.png';
import tiktok_icon from 'assets/icons/icon-tiktok.png';

import './Footer.css';

const Footer = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    return (
        <div id='footer-main-container'>
            <p id='footer-block1'>
                RESTAURANT/PIZZERIA
                <br />
                DANS LA TRADITION ARTISANALE
                <br />
                DEPUIS 1958
            </p>

            <p id='footer-block2'>
                NOTRE ÉTABLISSEMENT
                <br />
                VOUS ACCUEILLE 7J/7
            </p>

            <div id='footer-block3'>
                <p>
                    DE 10:00 À 16:00
                    <br />
                    &
                    <br />
                    DE 19:30 À 00:00
                </p>

                {windowWidth > 768 && (
                    <div id='social-networks'>
                        <a href='https://www.snapchat.com/add/hermitagesausse'>
                            <img
                                src={snap_icon}
                                alt="Icone Snapchat: Lien vers le compte officiel du Restaurant L'Hermitage de Sausset-les-Pins"
                            />
                        </a>
                        {/* COMPTE TIKTOK SUPPRIME PAR JEROME */}
                        {/* <a href='https://www.tiktok.com/@hermitagesausset'>
                            <img
                                src={tiktok_icon}
                                alt="Icone TikTok: Lien vers le compte TikTok officiel du Restaurant L'Hermitage de Sausset-les-Pins"
                            />
                        </a> */}
                    </div>
                )}
            </div>

            <div id='footer-block4'>
                <a
                    href='tel:0033442450982'
                    id='footer-block4'>
                    RÉSERVATIONS AU
                    <br />
                    04 42 45 09 82
                </a>

                {windowWidth > 767 && (
                    <div id='social-networks'>
                        <a href='https://www.facebook.com/profile.php?id=61550645705830'>
                            <img
                                src={fb_icon}
                                alt="Icone Facebook: Lien vers la page Facebook officielle du Restaurant L'Hermitage de Sausset-les-Pins"
                            />
                        </a>
                        <a href='https://www.instagram.com/hermitage_sausset_les_pins/'>
                            <img
                                src={insta_icon}
                                alt="Icone Instagram: Lien vers le compte Instagram officiel du Restaurant L'Hermitage de Sausset-les-Pins"
                            />
                        </a>
                    </div>
                )}

                {windowWidth < 768 && (
                    <div id='social-networks-links-mobile'>
                        <a href='https://www.facebook.com/profile.php?id=61550645705830'>
                            <img
                                src={fb_icon}
                                alt="Icone Facebook: Lien vers la page Facebook officielle du Restaurant L'Hermitage de Sausset-les-Pins"
                            />
                        </a>
                        <a href='https://www.instagram.com/hermitage_sausset_les_pins/'>
                            <img
                                src={insta_icon}
                                alt="Icone Instagram: Lien vers le compte Instagram officiel du Restaurant L'Hermitage de Sausset-les-Pins"
                            />
                        </a>
                        <a href='https://www.snapchat.com/add/hermitagesausse'>
                            <img
                                src={snap_icon}
                                alt="Icone Snapchat: Lien vers le compte Snapchat officiel du Restaurant L'Hermitage de Sausset-les-Pins"
                            />
                        </a>
                        {/* <a href='https://www.tiktok.com/@hermitagesausset'>
                            <img
                                src={tiktok_icon}
                                alt="Icone TikTok: Lien vers le compte TikTok officiel du Restaurant L'Hermitage de Sausset-les-Pins"
                            />
                        </a> */}
                    </div>
                )}
            </div>

            <p id='footer-block5'>
                22, PROM. DE LA CORNICHE,
                <br />
                13960 SAUSSET-LES-PINS
            </p>

            <div id='footer-block6'>
                <iframe
                    id='iframe-small'
                    className='iframe'
                    src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11609.215467333448!2d5.1190101!3d43.3288301!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12c9def6506c5873%3A0x1de3c864e9971042!2sL&#39;Hermitage!5e0!3m2!1sen!2sfr!4v1674753967022!5m2!1sen!2sfr'
                    width='400'
                    height='300'
                    allowFullScreen=''
                    loading='lazy'
                    referrerPolicy='no-referrer-when-downgrade'></iframe>

                <iframe
                    id='iframe-medium'
                    className='iframe hidden'
                    src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11609.215467333448!2d5.1190101!3d43.3288301!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12c9def6506c5873%3A0x1de3c864e9971042!2sL&#39;Hermitage!5e0!3m2!1sen!2sfr!4v1674753967022!5m2!1sen!2sfr'
                    width='600'
                    height='320'
                    allowFullScreen=''
                    loading='lazy'
                    referrerPolicy='no-referrer-when-downgrade'></iframe>

                <p id='footer-block7'>
                    Copyright © 2023 L&#39;Hermitage Sausset-les-Pins
                    {/* <br />
             - Site web créé par Nicolas CUBILIER */}
                </p>

                {/* <img src="" alt="" /> */}
            </div>
        </div>
    );
};

export default Footer;
