import {useEffect} from 'react'

// COLONNE 1 :
import Hermitage from 'assets/photos/Hermitage-exterieur.jpg';
import fourapizz1 from 'assets/photos/hermitage-fourapizz1.jpg';
import landing1 from 'assets/photos/landing-carousel-1.jpg';
import Histoire2 from 'assets/photos/Histoire-pic2-mobile.png';

// COLONNE 2 :
import landing3 from 'assets/photos/landing-carousel-3.jpg';
import vins from 'assets/photos/hermitage-vins.jpg';
import pizzadansfour from 'assets/photos/pizzadansfour.jpg';

// COLONNE 3 :
import landing2 from 'assets/photos/landing-carousel-2.jpg';
import viandecrue2 from 'assets/photos/viande-crue1.jpg';
import viande2 from 'assets/photos/viande2.png';

// COLONNE 4 :
import hermitage01 from 'assets/photos/hermitage01.jpg';
import landing4 from 'assets/photos/landing-carousel-4.jpg';
import horsdoeuvre2 from 'assets/photos/horsdoeuvre2.png';

// import AboutPic1 from 'assets/photos/About-pic1-desktop.png';
// import AboutPic2 from 'assets/photos/About-pic2-desktop.png';
// import bar from 'assets/photos/bar.jpg';
// import brochettestjacques1 from 'assets/photos/brochette-st-jacques.jpg';
// import brochettestjacques2 from 'assets/photos/brochette-st-jacques2.jpg';
// import dessert1 from 'assets/photos/dessert1.png';
// import dessert2 from 'assets/photos/dessert2.jpg';
// import dessert3 from 'assets/photos/dessert3.png';
// import entrecote1 from 'assets/photos/entrecote1.jpg';
// import hermitage02 from 'assets/photos/hermitage02.jpg';
// import hermitage03 from 'assets/photos/hermitage03.jpg';
// import hermitage04 from 'assets/photos/hermitage04.jpg';
// import hermitage05 from 'assets/photos/hermitage05.jpg';
// import hermitage06 from 'assets/photos/hermitage06.jpg';
// import hermitage08 from 'assets/photos/hermitage08.jpg';
// import hermitage09 from 'assets/photos/hermitage09.jpg';
// import hermitage10 from 'assets/photos/hermitage10.jpg';
// import salle2 from 'assets/photos/hermitage-salle-2-paysage.jpg';
// import viandes1 from 'assets/photos/hermitage-viandes-1.jpg';
// import vins1 from 'assets/photos/hermitage-vins1.jpg';
// import Histoire1 from 'assets/photos/Histoire-pic1-mobile.png';
// import horsdoeuvre1 from 'assets/photos/horsdoeuvre1.png';
// import magret1 from 'assets/photos/magret1.jpg';
// import pizza1 from 'assets/photos/pizza1.jpg';
// import pizza2 from 'assets/photos/pizza2.png';
// import plat1 from 'assets/photos/plat1.jpg';
// import plat3 from 'assets/photos/plat3.jpg';
// import plat5 from 'assets/photos/plat5.jpg';
// import plat6 from 'assets/photos/plat6.jpg';
// import plat7 from 'assets/photos/plat7.jpg';
// import plat8 from 'assets/photos/plat8.jpg';
// import plat10 from 'assets/photos/plat10.jpg';
// import plat11 from 'assets/photos/plat11.png';
// import plat13 from 'assets/photos/plat13.png';
// import poisson1 from 'assets/photos/poisson1.jpg';
// import poisson3 from 'assets/photos/poisson3.png';
// import tartare1 from 'assets/photos/tartare1.jpg';
// import viande3 from 'assets/photos/viande3.jpg';
// import viandecrue1 from 'assets/photos/viande-crue1.jpg';

import './GaleriePhotos.css';

const GaleriePhotos = () => {

  useEffect(() => {
    document.title = "Restaurant L'HERMITAGE Sausset-les-Pins - Galerie Photos";  
  }, []);

    return (
<>
<h1>Galerie Photos</h1>

        <div className='row'>
            {/* 1ERE COLONNE : */}
            <div className='column'>
                <img
                    src={Hermitage}
                    alt=''
                />

                <img
                    src={fourapizz1}
                    alt=''
                />

                <img
                    src={landing1}
                    alt=''
                />
<img
                    src={Histoire2}
                    alt=''
                />


                {/* <img
                    src={''}
                    alt=''
                /> */}
            </div>

            {/* 2EME COLONNE : */}
            <div className='column'>
                <img
                    src={landing3}
                    alt=''
                />

                <img
                    src={vins}
                    alt=''
                />

                <img
                    src={pizzadansfour}
                    alt=''
                />

                {/* <img
                    src={''}
                    alt=''
                /> */}
            </div>

            {/* 3EME COLONNE : */}
            <div className='column'>
                <img
                    src={landing2}
                    alt=''
                />

                <img
                    src={viandecrue2}
                    alt=''
                />

                <img
                    src={viande2}
                    alt=''
                />

                {/* <img
                    src={''}
                    alt=''
                 /> */}
            </div>

            {/* 4EME COLONNE : */}
            <div className='column'>
                <img
                    src={hermitage01}
                    alt=''
                />

                <img
                    src={landing4}
                    alt=''
                />

                <img
                    src={horsdoeuvre2}
                    alt=''
                />

                {/* <img
                    src={''}
                    alt=''
                /> */}
            </div>
        </div>
</>
    );
};

export default GaleriePhotos;
