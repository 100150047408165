import leMenu from 'data/Menu_2022';

import losange from 'assets/icons/mini-losanges.png';

import '../LaCarte.css';
import PricingInfo from 'components/PricingInfo';

const LesDesserts = () => {
    const menu = leMenu.lesDesserts;

    return (
        <>
            <div className='menu-subsection-container'>
                <h2 className='menu-subsection-title'>LES DESSERTS</h2>

                <div className='subsection-meals-list'>
                    <div className='dessert-subtitle'>
                        {/* <h3>LES FROMAGES</h3> */}
                        {menu.map((plat) => {
                            {
                                if (plat.categorie === '')
                                    return (
                                        <div
                                            key={`container-${plat.nom}`}
                                            className='menu-item'>
                                            <div className='menu-left'>
                                                <img
                                                    src={losange}
                                                    className='losange-menu-subsection'
                                                    key={`losange-${plat.nom}`}
                                                    alt='losange'
                                                />

                                                <div className='menu-middle'>
                                                    <h4
                                                        className='meal-name'
                                                        key={menu.nom}>
                                                        {plat.nom}
                                                    </h4>

                                                    {plat.ingredients && (
                                                        <p
                                                            className='ingredients'
                                                            key={`description-${plat.nom}`}>
                                                            ({plat.ingredients})
                                                        </p>
                                                    )}
                                                </div>
                                            </div>

                                            <div className='menu-right'>
                                                <h4 className='price'>{plat.prix}</h4>
                                            </div>
                                        </div>
                                    );
                            }
                        })}
                    </div>

                    <div className='dessert-subtitle'>
                        <h3>LES GLACES & SORBETS</h3>
                        {menu.map((plat) => {
                            {
                                if (plat.categorie === 'GLACES & SORBETS')
                                    return (
                                        <div
                                            key={`container-${plat.nom}`}
                                            className='menu-item'>
                                            <div className='menu-left'>
                                                <img
                                                    src={losange}
                                                    className='losange-menu-subsection'
                                                    key={`losange-${plat.nom}`}
                                                    alt='losange'
                                                />

                                                <div className='menu-middle'>
                                                    <h4
                                                        className='meal-name'
                                                        key={menu.nom}>
                                                        {plat.nom}
                                                    </h4>

                                                    {plat.ingredients && (
                                                        <p
                                                            className='ingredients'
                                                            key={`description-${plat.nom}`}>
                                                            ({plat.ingredients})
                                                        </p>
                                                    )}
                                                </div>
                                            </div>

                                            <div className='menu-right'>
                                                <h4 className='price'>{plat.prix}</h4>
                                            </div>
                                        </div>
                                    );
                            }
                        })}
                    </div>

                    <div className='dessert-subtitle'>
                        <h3>LES DESSERTS MAISON</h3>
                        {menu.map((plat) => {
                            {
                                if (plat.categorie === 'DESSERTS MAISON')
                                    return (
                                        <div
                                            key={`container-${plat.nom}`}
                                            className='menu-item'>
                                            <div className='menu-left'>
                                                <img
                                                    src={losange}
                                                    className='losange-menu-subsection'
                                                    key={`losange-${plat.nom}`}
                                                    alt='losange'
                                                />

                                                <div className='menu-middle'>
                                                    <h4
                                                        className='meal-name'
                                                        key={menu.nom}>
                                                        {plat.nom}
                                                    </h4>

                                                    {plat.ingredients && (
                                                        <p
                                                            className='ingredients'
                                                            key={`description-${plat.nom}`}>
                                                            ({plat.ingredients})
                                                        </p>
                                                    )}
                                                </div>
                                            </div>

                                            <div className='menu-right'>
                                                <h4 className='price'>{plat.prix}</h4>
                                            </div>
                                        </div>
                                    );
                            }
                        })}
                    </div>

                    <div className='dessert-subtitle'>
                        <h3>LES FRUITS EN SAISON</h3>
                        {menu.map((plat) => {
                            {
                                if (plat.categorie === 'FRUITS')
                                    return (
                                        <div
                                            key={`container-${plat.nom}`}
                                            className='menu-item'>
                                            <div className='menu-left'>
                                                <img
                                                    src={losange}
                                                    className='losange-menu-subsection'
                                                    key={`losange-${plat.nom}`}
                                                    alt='losange'
                                                />

                                                <div className='menu-middle'>
                                                    <h4
                                                        className='meal-name'
                                                        key={menu.nom}>
                                                        {plat.nom}
                                                    </h4>

                                                    {plat.ingredients && (
                                                        <p
                                                            className='ingredients'
                                                            key={`description-${plat.nom}`}>
                                                            ({plat.ingredients})
                                                        </p>
                                                    )}
                                                </div>
                                            </div>

                                            <div className='menu-right'>
                                                <h4 className='price'>{plat.prix}</h4>
                                            </div>
                                        </div>
                                    );
                            }
                        })}
                    </div>

                    <div className='dessert-subtitle'>
                        <h3>LES GLACES & SORBETS COMPOSÉS</h3>
                        {menu.map((plat) => {
                            {
                                if (plat.categorie === 'GLACES & SORBETS COMPOSÉS')
                                    return (
                                        <div
                                            key={`container-${plat.nom}`}
                                            className='menu-item'>
                                            <div className='menu-left'>
                                                <img
                                                    src={losange}
                                                    className='losange-menu-subsection'
                                                    key={`losange-${plat.nom}`}
                                                    alt='losange'
                                                />

                                                <div className='menu-middle'>
                                                    <h4
                                                        className='meal-name'
                                                        key={menu.nom}>
                                                        {plat.nom}
                                                    </h4>

                                                    {plat.ingredients && (
                                                        <p
                                                            className='ingredients'
                                                            key={`description-${plat.nom}`}>
                                                            ({plat.ingredients})
                                                        </p>
                                                    )}
                                                </div>
                                            </div>

                                            <div className='menu-right'>
                                                <h4 className='price'>{plat.prix}</h4>
                                            </div>
                                        </div>
                                    );
                            }
                        })}
                    </div>
                </div>

                <a
                    className='retour-vers-carte'
                    href='/la-carte'>
                    <button className='cta-btn'>RETOUR</button>
                </a>
            </div>

            <PricingInfo />
        </>
    );
};

export default LesDesserts;
