import leMenu from 'data/Menu_2022';

import losange from 'assets/icons/mini-losanges.png';

const CartePorc = () => {
    const menu = leMenu.lesViandes;

    return (
        <div className='subsection-meals-list'>
            <h3>Le Porc</h3>
            {menu.map((plat) => {
                // chercher parmis tous les plats, ceux qui ont: `categorie: "Le Porc"` et afficher le contenu de chaque plat en respectant le modele de la carte du resto
                if (plat.categorie === 'Le Porc')
                    return (
                        <>
                            <div
                                key={`container-${plat.nom}`}
                                className='menu-item'>
                                <div
                                    className='menu-left'
                                    key={`menu-left-${plat.nom}`}>
                                    <img
                                        src={losange}
                                        className='losange-menu-subsection'
                                        key={`losange-${plat.nom}`}
                                        alt='losange'
                                    />

                                    <div
                                        className='menu-middle'
                                        key={`menu-middle-${plat.nom}`}>
                                        <div
                                            style={{ display: 'flex' }}
                                            key={`zeubi-${plat.nom}`}>
                                            <h4
                                                className='meal-name'
                                                key={menu.nom}>
                                                {plat.nom}
                                            </h4>
                                        </div>

                                        {plat.rappel && (
                                            <h4
                                                className='rappel-viandes'
                                                key={`rappel-${plat.nom}`}>
                                                ({plat.rappel})
                                            </h4>
                                        )}

                                        {plat.ingredients && (
                                            <p
                                                className='details-menu'
                                                key={`description-${plat.nom}`}>
                                                {plat.ingredients}
                                            </p>
                                        )}
                                    </div>
                                </div>

                                <div
                                    className='menu-right'
                                    key={`menu-right-${plat.nom}`}>
                                    <h4
                                        className='price'
                                        key={`price-${plat.nom}`}>
                                        {plat.prix}
                                    </h4>
                                </div>
                            </div>
                        </>
                    );
            })}
        </div>
    );
};

export default CartePorc;
