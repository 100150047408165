import { useState, useEffect } from 'react';

import scrollToFooter from 'services/scrollToFooter';

import logoNavbarMobile from 'assets/logos/logo-navbar-mobile.png';
import logoNavbarDesktop from 'assets/logos/logo-navbar-desktop.png';

import './Navbar.css';

const Navbar = () => {
    const [Open, setOpen] = useState(false);
    const [sticky, setSticky] = useState();

    useEffect(() => {
        const handleScroll = () => setSticky(window.scrollY > 200); // (change value if necessary)
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    });

    return (
        <header className={`${sticky ? 'sticky' : ''}`}>
            <nav className='Navbar'>
                <a href='/'>
                    <img
                        id='nav-logo-mobile'
                        className='nav-logo'
                        src={logoNavbarMobile}
                        alt="logo du Restaurant l'Hermitage de Sausset-les-Pins (cliquer ici pour retourner sur la page d'accueil)"
                    />
                    <img
                        id='nav-logo-desktop'
                        className='nav-logo'
                        src={logoNavbarDesktop}
                        alt="logo du Restaurant l'Hermitage de Sausset-les-Pins (cliquer ici pour retourner sur la page d'accueil)"
                    />
                </a>

                <div className={`nav-items ${Open && 'open'}`}>
                    <a href='/notre-histoire'>Notre Histoire</a>

                    <a href='/la-carte'>La Carte</a>

                    <p onClick={scrollToFooter}>Adresse & Horaires</p>

                    <a href='/galerie-photos'>Galerie Photo</a>
                </div>

                <div
                    className={`nav-toggle ${Open && 'open'}`}
                    onClick={() => setOpen(!Open)}>
                    <div className='bar'></div>
                </div>
            </nav>
        </header>
    );
};

export default Navbar;
