import aboutPic1Mobile from 'assets/photos/About-pic1-mobile.png';
import aboutPic2Mobile from 'assets/photos/About-pic2-mobile.png';
import aboutPic1Desktop from 'assets/photos/About-pic1-desktop.png';
import aboutPic2Desktop from 'assets/photos/About-pic2-desktop.png';
import miniLosange from 'assets/icons/mini-losanges.png';

import scrollToFooter from 'services/scrollToFooter';

import './AboutSection.css';

const AboutSection = () => {
    return (
        <div id='aboutsection-main-container'>
            <div id='aboutsection-title'>
                <h1>À PROPOS</h1>
            </div>

            <div id='aboutsection-text1'>
                <p>Établissement ouvert depuis 1958</p>

                <img
                    className='minilosange-mobile'
                    src={miniLosange}
                    alt='petit losange rose'
                />

                <p>Un cadre magnifique (terrasse et salle offrent une splendide vue mer)</p>

                <img
                    className='minilosange-mobile'
                    src={miniLosange}
                    alt='petit losange rose'
                />

                <p>Cuisine Provençale / Méditérranéenne</p>
            </div>

            <div id='aboutsection-img1-div'>
                <img
                    className='aboutsection-img img-mobile'
                    src={aboutPic1Mobile}
                    alt="Buvette de l'Hermitage à l'époque du camping (en noir et blanc - format mobile)"
                />
                <img
                    className='aboutsection-img img-desktop'
                    src={aboutPic1Desktop}
                    alt="Buvette de l'Hermitage à l'époque du camping (en noir et blanc)"
                />
            </div>

            <div id='aboutsection-btn1-box'>
                <a href='/notre-histoire'>
                    <button className='aboutsection-btn cta-btn'>NOTRE HISTOIRE</button>
                </a>
            </div>

            <div id='aboutsection-text2'>
                {/* <div id='aboutsection-horizontal-divider-box'>
                    <hr className='aboutsection-horizontal-divider' />
                </div> */}

                <p>
                    Véritable four à bois artisanal pour nos
                    <br />
                    pizzas et grillades au feu de bois
                </p>

                <img
                    className='minilosange-mobile'
                    src={miniLosange}
                    alt='petit losange rose'
                />

                <p>Des produits de première qualité</p>

                <img
                    className='minilosange-mobile'
                    src={miniLosange}
                    alt='petit losange rose'
                />

                <p>Nos viandes de boeuf sont sélectionnées et maturées avec soin</p>

                <img
                    className='minilosange-mobile'
                    src={miniLosange}
                    alt='petit losange rose'
                />

                <p>Notre restaurant dispose de son parking privé</p>
            </div>

            <div id='aboutsection-img2-div'>
                <img
                    className='aboutsection-img img-mobile'
                    src={aboutPic2Mobile}
                    alt="Buvette de l'Hermitage à l'époque du camping vue de la mer (colorisée - format mobile)"
                />
                <img
                    className='aboutsection-img img-desktop'
                    src={aboutPic2Desktop}
                    alt="Buvette de l'Hermitage à l'époque du camping vue de la mer (colorisée)"
                />
            </div>

            <div id='aboutsection-btn2-box'>
                <button
                    onClick={scrollToFooter}
                    className='aboutsection-btn cta-btn'>
                    ADRESSE & HORAIRES
                </button>
            </div>
        </div>
    );
};

export default AboutSection;
