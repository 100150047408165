/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react';

import './MenuCarousel.css';

export const MenuCarouselItem = ({ children, width, numero }) => {
    return (
        <div
            // id={`menu-carousel-pic-${numero}`}
            className='menu-carousel-item'
            style={{ width: width }}>
            {children}
        </div>
    );
};

const MenuCarousel = ({ children }) => {
    const [activeIndexMenuCarousel, setActiveIndexMenuCarousel] = useState(0);

    // This method will receive a new index and check if the 'active' item is the first item or the last item:
    // this will the carousel infinite (it starts back after displaying the last picture)
    const updateIndex = (newIndex) => {
        // If the new index less than 0, we just reset it reset it to number of items — 1
        if (newIndex < 0) {
            newIndex = React.Children.count(children) - 1;
        }
        // If the new index equal or greater than number of items , just reset it to 0
        else if (newIndex >= React.Children.count(children)) {
            newIndex = 0;
        }

        // Call setActiveIndexMenuCarousel to update the active state
        setActiveIndexMenuCarousel(newIndex);
    };

    // Auto-cycle for infinite-carousel:
    useEffect(() => {
        const interval = setInterval(() => {
            updateIndex(activeIndexMenuCarousel + 1);
        }, 3000);
        // interval of 3000 miliseconds = 3 seconds. (That means it will execute the updateIndex() method every 2 seconds)

        return () => {
            if (interval) {
                clearInterval(interval);
                // we need to do the clean-up to ensure our interval will stop running when our component is unmount
            }
        };
    });

    return (
        <div className='menu-carousel'>
            <div
                className='menu-carousel-inner'
                style={{ transform: `translateX(-${activeIndexMenuCarousel * 100}%)` }}>
                {React.Children.map(children, (child, index) => {
                    return React.cloneElement(child, { width: '100%' });
                })}
            </div>

            <button
                onClick={() => {
                    updateIndex(activeIndexMenuCarousel - 1);
                }}>
                {/* prev */} &#60;
            </button>

            {React.Children.map(children, (child, index) => {
                return (
                    <button
                        className={`${index === activeIndexMenuCarousel ? 'active' : ''}`}
                        onClick={() => {
                            updateIndex(index);
                        }}>
                        {index + 1}
                    </button>
                );
            })}

            <button
                onClick={() => {
                    updateIndex(activeIndexMenuCarousel + 1);
                }}>
                {/* next */} &#62;
            </button>
        </div>
    );
};

export default MenuCarousel;
