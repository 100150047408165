import CarteBoeuf from './CarteBoeuf';
import CartePorc from './CartePorc';
import CarteAgneau from './CarteAgneau';
import CarteCanard from './CarteCanard';
import PricingInfo from 'components/PricingInfo';

const LesViandes = () => {
    return (
        <>
            <div className='menu-subsection-container'>
                <h2 className='menu-subsection-title'>LES VIANDES</h2>

                <CarteBoeuf />
                <CartePorc />
                <CarteAgneau />
                <CarteCanard />

                <a
                    className='retour-vers-carte'
                    href='/la-carte'>
                    <button className='cta-btn'>RETOUR</button>
                </a>
            </div>

            <PricingInfo />
        </>
    );
};

export default LesViandes;
