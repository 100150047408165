import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';

import Navbar from 'components/Navbar/Navbar';

import HomePage from 'pages/HomePage/HomePage';
import Histoire from 'pages/NotreHistoire/Histoire';
import CarteResto from 'components/CarteResto/CarteResto';
import LesHorsDoeuvre from 'pages/LaCarte/LesHorsDoeuvre/LesHorsDoeuvre';
import LesPizzas from 'pages/LaCarte/LesPizzas/LesPizzas';
import LesViandes from 'pages/LaCarte/LesViandes/LesViandes';
import LesPoissons from 'pages/LaCarte/LesPoissons/LesPoissons';
import LesDesserts from 'pages/LaCarte/LesDesserts/LesDesserts';
import Galerie from 'pages/GaleriePhotos/GaleriePhotos';

import MiniFooter from 'components/Footers/MiniFooter/MiniFooter';
import Footer from 'components/Footers/Footer/Footer';

const App = () => {

  const currentLocation = window.location.pathname;

  useEffect(() => {
    if (currentLocation.includes('la-carte/les-') ) {

      document.title = "Carte du Restaurant L'HERMITAGE - Sausset les Pins";  
    }
  }, []);

    return (
        <>
            <Navbar />

            <main>
                <Routes>
                    <Route
                        index
                        element={<HomePage />}
                    />
                    {/* <Route
                        path='home'
                        element={<Home />}
                    /> */}

                    {/* PAGES ISOLEES POUR VERSION MOBILE: */}
                    <Route
                        path='la-carte/les-hors-doeuvre'
                        element={<LesHorsDoeuvre />}
                    />
                    <Route
                        path='la-carte/les-pizzas'
                        element={<LesPizzas />}
                    />
                    <Route
                        path='la-carte/les-viandes'
                        element={<LesViandes />}
                    />
                    <Route
                        path='la-carte/les-poissons'
                        element={<LesPoissons />}
                    />
                    <Route
                        path='la-carte/les-desserts'
                        element={<LesDesserts />}
                    />

                    <Route
                        path='notre-histoire'
                        element={<Histoire />}
                    />

                    <Route
                        path='la-carte'
                        element={<CarteResto />}>                        
                        {/* NESTED ROUTES POUR VERSION DESKTOP AMELIOREE AVEC 'SWITCH': */}
                        <Route
                            path='les-hors-doeuvre'
                            element={<LesHorsDoeuvre />}
                        />
                        <Route
                            path='les-pizzas'
                            element={<LesPizzas />}
                        />
                        <Route
                            path='les-viandes'
                            element={<LesViandes />}
                        />
                        <Route
                            path='les-poissons'
                            element={<LesPoissons />}
                        />
                        <Route
                            path='les-desserts'
                            element={<LesDesserts />}
                        />
                    </Route>

                    <Route
                        path='/galerie-photos'
                        element={<Galerie />}
                    />
                </Routes>
            </main>

            <footer>
                <MiniFooter />
                <Footer />
            </footer>
        </>
    );
};

export default App;

// ----------------------------------------------------------------------------------
// ANCIENNE VERSION :

// import { Routes, Route } from 'react-router-dom';

// import NavbarManager from 'components/Navigation/Navbar/NavbarManager.jsx';

// import HomePage from 'pages/HomePage';
// import Histoire from 'pages/NotreHistoire';
// import LaCarte from 'pages/LaCarte';
// import Galerie from 'pages/GaleriePhotos';

// import LesHorsDoeuvre from 'pages/LaCarte/LesHorsDoeuvre';
// import LesPizzas from 'pages/LaCarte/LesPizzas';
// import LesViandes from 'pages/LaCarte/LesViandes';
// import LesPoissons from 'pages/LaCarte/LesPoissons';
// import LesDesserts from 'pages/LaCarte/LesDesserts';

// import Footer from 'components/Footer';

// import './App.css';

// const App = () => {
//     return (
//         <>
//             <NavbarManager />
//             <main>
//                 <Routes>
//                     <Route
//                         index
//                         element={<HomePage />}
//                     />

//                     {/* PAGES ISOLEES POUR VERSION MOBILE: */}
//                     <Route
//                         path='la-carte/les-hors-doeuvre'
//                         element={<LesHorsDoeuvre />}
//                     />
//                     <Route
//                         path='la-carte/les-pizzas'
//                         element={<LesPizzas />}
//                     />
//                     <Route
//                         path='la-carte/les-viandes'
//                         element={<LesViandes />}
//                     />
//                     <Route
//                         path='la-carte/les-poissons'
//                         element={<LesPoissons />}
//                     />
//                     <Route
//                         path='la-carte/les-desserts'
//                         element={<LesDesserts />}
//                     />

//                     <Route
//                         path='notre-histoire'
//                         element={<Histoire />}
//                     />

//                     <Route
//                         path='la-carte'
//                         element={<LaCarte />}>
//                         {/*  */}
//                         {/* NESTED ROUTES POUR VERSION DESKTOP AMELIOREE AVEC 'SWITCH': */}
//                         <Route
//                             path='les-hors-doeuvre'
//                             element={<LesHorsDoeuvre />}
//                         />
//                         <Route
//                             path='les-pizzas'
//                             element={<LesPizzas />}
//                         />
//                         <Route
//                             path='les-viandes'
//                             element={<LesViandes />}
//                         />
//                         <Route
//                             path='les-poissons'
//                             element={<LesPoissons />}
//                         />
//                         <Route
//                             path='les-desserts'
//                             element={<LesDesserts />}
//                         />
//                     </Route>

//                     <Route
//                         path='/galerie-photos'
//                         element={<Galerie />}
//                     />
//                 </Routes>
//             </main>

//             <Footer />
//         </>
//     );
// };

// export default App;
