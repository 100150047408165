import LandingCarousel from 'components/Carousels/LandingCarousel/LandingCarousel';
import LandingSideBlock from 'components/LandingSideBlock/LandingSideBlock';

import './LandingSection.css';

const LandingSection = () => {
    return (
        <div id='landing-section-main'>
            <LandingCarousel />
            <LandingSideBlock /> {/*  only visible on bigger displays */}
        </div>
    );
};

export default LandingSection;
