import logoVignette from 'assets/logos/vignette-desktop.png';

import './LandingSideBlock.css';

const LandingSideBlock = () => {
    return (
        <div id='landing-sideblock-main'>
            <div id='vignette'>
                <img
                    src={logoVignette}
                    alt="logo du restaurant l'Hermitage Sausset-les-Pins"
                />
            </div>
        </div>
    );
};

export default LandingSideBlock;
